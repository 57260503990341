import React, { useEffect } from 'react';
import { SquareLoader } from 'react-spinners';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { REGISTER_USER_MUTATION } from '../code/graphql';
import './ShareLink.scss';
import './ShareLinkRegister.scss';
import { auth } from '../code/firebase';

export default function ShareLinkRegister() {
  const { referralCode } = useParams();
  const [registerUserFunction, registerUserMutation] = useMutation(REGISTER_USER_MUTATION);

  useEffect(() => {
    console.log('registering user: ' + referralCode);
    registerUserFunction({
      variables: {
        input: {
          referralCode,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (registerUserMutation.data) {
      console.log('registered: ' + JSON.stringify(registerUserMutation.data));
    }
  }, [registerUserMutation.data]);

  if (!registerUserMutation.called || registerUserMutation.loading) {
    return (
      <div className="share-link-loading">
        <SquareLoader size={150} color="#FFF" />
      </div>
    );
  }

  async function logoutClicked() {
    await auth.signOut();
  }

  return (
    <div className="share-link-inner">
      <h2>You’re in!</h2>
      <div className="social-share-box">
        <h4 className="download-header">Now Download the App</h4>
        <img className="qr-code" src="../app-qrcode.svg" alt="Giddy App Download" />
        <div className="download-box">
          <a href="https://apps.apple.com/us/app/keynote/id1596780905">
            <img src="../icon-get-apple.png" alt="Download on the App Store" width={150} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.getdefiq.giddy">
            <img src="../icon-get-google.png" alt="Get it on Google Play" width={150} />
          </a>
        </div>
      </div>
      <button onClick={logoutClicked}>LOGOUT (THIS IS FOR TESTING)</button>
    </div>
  );
}
