import { initializeApp } from 'firebase/app';
import { getAuth, OAuthProvider, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, signInWithRedirect, setPersistence, inMemoryPersistence } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCfAHvVKEEL2hFqKrRIYWeKHmlhpkgIs3Y',
  authDomain: 'defiq-a98c1.firebaseapp.com',
  projectId: 'defiq-a98c1',
  storageBucket: 'defiq-a98c1.appspot.com',
  messagingSenderId: '1019504651311',
  appId: '1:1019504651311:web:270b70f9be4ff9bbd76ada',
};

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

export async function signInGoogle() {
  try {
    await setPersistence(auth, inMemoryPersistence);
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    await signInWithRedirect(auth, provider);
  } catch (err) {
    console.error(err);
  }
}

export async function signInFacebook() {
  try {
    const provider = new FacebookAuthProvider();
    provider.addScope('user_birthday');

    await signInWithRedirect(auth, provider);
  } catch (err) {
    console.error(err);
  }
}

export async function signInApple() {
  try {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    await signInWithRedirect(auth, provider);
  } catch (err) {
    console.error(err);
  }
}

export async function signInTwitter() {
  try {
    const provider = new TwitterAuthProvider();
    await signInWithRedirect(auth, provider);
  } catch (err) {
    console.error(err);
  }
}
