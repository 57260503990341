import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { client } from './code/graphql';
// import App from './components/App';
import ShareLink from './components/ShareLink';

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ShareLink />} />
        <Route path="/sharelink/:referralCode" element={<ShareLink />} />
      </Routes>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root'),
);
