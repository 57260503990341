import './ShareLink.scss';
import React, { useEffect, useState } from 'react';
import { SquareLoader } from 'react-spinners';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, signInGoogle, signInFacebook, signInApple, signInTwitter } from '../code/firebase';
import ShareLinkRegister from './ShareLinkRegister';
import { setGraphQLToken } from '../code/graphql';

export default function ShareLink() {
  const [user, userLoading] = useAuthState(auth);
  const [userInitialized, setUserInitaliazed] = useState(false);

  useEffect(() => {
    if (!userLoading) {
      if (!user) {
        setUserInitaliazed(false);
        setGraphQLToken('');
      } else {
        user.getIdToken().then(token => {
          setGraphQLToken(token);
          setUserInitaliazed(true);
        });
      }
    }
  }, [user, userLoading]);

  let content = (
    <div className="share-link-inner">
      <h2>Grow Your Crypto</h2>
      <p>Giddy is a new way to Defi sed posuere consectetur est at lobortis. Cum sociis natoque penatibus et magnis dis parturient.</p>
      <div className="social-share-box">
        <h4 className="share-link-join">Sign In to Join the Waitlist</h4>
        <button className="login-button" type="button" onClick={signInGoogle}>
          <img src="../icon-google.png" alt="Google" width={26} />
          <span className="login-text">Google</span>
        </button>
        <button className="login-button" type="button" onClick={signInApple}>
          <img src="../icon-apple.png" alt="Google" width={26} />
          <span className="login-text">Apple</span>
        </button>
        <button className="login-button" type="button" onClick={signInFacebook}>
          <img src="../icon-facebook.png" alt="Google" width={26} />
          <span className="login-text">Facebook</span>
        </button>
        <button className="login-button" type="button" onClick={signInTwitter}>
          <img src="../icon-twitter.png" alt="Google" width={26} />
          <span className="login-text">Twitter</span>
        </button>
      </div>
    </div>
  );

  if (userLoading) {
    content = (
      <div className="share-link-loading">
        <SquareLoader size={150} color="#FFF" />
      </div>
    );
  }

  if (user && userInitialized) {
    content = <ShareLinkRegister />;
  }

  return (
    <div className="share-link">
      <img className="share-link-logo" src="../giddy-logo-03.svg" alt="Giddy Logo" />
      { content }
    </div>
  );
}
