import { ApolloClient, createHttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: 'https://api.giddystaging.com/g',
  // uri: 'https://api.defiq.finance/g',
  // uri: 'http://localhost:8080/g',
});

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export function setGraphQLToken(token: string) {
  if (client) {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });
    client.setLink(authLink.concat(httpLink));
  }
}

export const ME_QUERY = gql`
query ME_QUERY {
  me {
    email
    firebaseUid
    firstName
    id
    isActive
    isStaff
    isSuperUser
    lastName
    leftWaitlistAt
    referralCode
    useTestNet
    waitlistPosition {
      position
      score
    }
  }
}
`;

export const REGISTER_USER_MUTATION = gql`
mutation REGISTER_USER_MUTATION($input: RegisterUserInput) {
  registerUser(input: $input) {
    ... on RegisterUserFailure {
      errors {
        ... on NotAuthenticatedError {
          message
          title
        }
      }
    }
    ... on RegisterUserSuccess {
      user {
        email
        firebaseUid
        firstName
        id
        isActive
        isStaff
        isSuperUser
        lastName
        leftWaitlistAt
        referralCode
      }
    }
  }
}
`;
